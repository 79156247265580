import type { Directive, DirectiveBinding } from 'vue'
interface ElType extends HTMLElement {
  __handleClick__: (event: MouseEvent) => any
  disabled: boolean
}
const throttle: Directive = {
  mounted(el: ElType, binding: DirectiveBinding) {
    if (typeof binding.value !== 'function') return
    const duration = binding.arg ? parseInt(binding.arg) : 2000
    // let timer: NodeJS.Timeout | null = null
    let timer: number | null = null
    el.__handleClick__ = function (event: MouseEvent) {
      if (timer) clearTimeout(timer)
      if (!el.disabled) {
        el.disabled = true
        el.classList.add('n-button--disabled')
        binding.value(event)
        timer = setTimeout(() => {
          el.disabled = false
          el.classList.remove('n-button--disabled')
        }, duration)
      }
    }
    el.addEventListener('click', el.__handleClick__)
  },
  beforeUnmount(el: ElType) {
    el.removeEventListener('click', el.__handleClick__)
  },
}
export default throttle
