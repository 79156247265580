import { createStore } from 'vuex'
// 引用公共路由表
import { publicRoutes } from '@/router/index'
import http from '../../public/static/http/api'

export default createStore({
  state: {
    // 用户信息
    userInfo: {},
    // 路由表：初始拥有静态路由权限
    routes: publicRoutes,
  },
  getters: {
    // 获取用户信息
    userInfo(state) {
      return state.userInfo
    },
  },
  mutations: {
    // 存储用户信息
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },

    // 增加路由
    setRoutes(state, newRoutes) {
      // 永远在静态路由的基础上增加新路由
      state.routes = [...publicRoutes, ...newRoutes]
    },
  },
  actions: {
    // 获取用户信息
    async getUserInfo(context) {
      // const res = await getUserInfo()

      // 递归处理userInfo接口获取到的权限信息和userMenu接口获取到的菜单是否有相同权限
      const filterChilds = function (childs: any, b: any) {
        return childs.reduce((acc: any, child: any) => {
          // 如果child的perms为空字符串或在b数组中，则保留child
          if (child.data.perms === '' || b.includes(child.data.perms)) {
            // 如果child有childs数组，递归处理它们
            if (child.childs) {
              child.childs = filterChilds(child.childs, b)
            }
            // 将child添加到累积数组中
            acc.push(child)
          }
          // 返回累积数组
          return acc
        }, []) // 初始累积数组为空数组
      }

      // 多级数组转一级
      const getAllList = function (arr: any) {
        var res: any = []
        // var res: any = {
        //   menus: [],
        //   points: [],
        // }
        for (let i = 0; i < arr.length; i++) {
          if (Array.isArray(arr[i].childs)) {
            if (arr[i].href != '') {
              // res.push(arr[i].href)
              res.push({
                href: arr[i].href,
                perms: arr[i].data.perms,
              })
            }
            res = res.concat(getAllList(arr[i].childs))
          } else {
            if (arr[i].href != '') {
              // res.push(arr[i].href)
              res.push({
                href: arr[i].href,
                perms: arr[i].data.perms,
              })
            }
          }
        }
        return res
      }

      // 存储多级处理过的数据
      interface resInfoType {
        menus?: any
        points?: any
        userPageMenu?: any
      }
      const resInfo: resInfoType = {}

      // 用户拥有权限
      let userHasPermission: any = []

      // 请求用户信息接口
      let data = {
        custId: sessionStorage.getItem('manageCustId'),
        roleDesc: sessionStorage.getItem('manageRoleDesc'),
        userId: sessionStorage.getItem('manageUserId'),
      }
      await http
        .getUserInfo({ data })
        .then((res: any) => {
          // console.log(res)
          // console.log(res.data.stringPermissions)
          userHasPermission = res.data.stringPermissions
        })
        .catch((err: any) => {
          console.log(err)
        })

      // 请求用户菜单接口
      await http
        .getUserMenus({ data })
        .then((res: any) => {
          // console.log(res)

          // 筛选出用户存在权限的操作菜单用于页面展示
          let userPageMenu = res.data.childs
            .map((item: any) => {
              // 如果item的perms为空字符串或在b数组中，则保留item
              if (
                item.data.perms === '' ||
                userHasPermission.includes(item.data.perms)
              ) {
                // 如果item有childs数组，递归处理它们
                if (item.childs) {
                  item.childs = filterChilds(item.childs, userHasPermission)
                }
                // 返回修改后的item
                return item
              }
              // perms不为空且不在b数组中，不保留item
              return null
            })
            .filter((item: any) => item !== null) // 移除null值（即不满足条件的对象）

          // 处理接口数据，根据用户信息接口匹配菜单接口拥有权限
          let portReturnLimit = getAllList(res.data.childs)
          let userActualAuthority: any = []
          portReturnLimit.forEach((val: any) => {
            if (userHasPermission.includes(val.perms) != -1) {
              userActualAuthority.push(val.href)
            }
          })

          resInfo.menus = userActualAuthority
          // resInfo.userPageMenu = userPageMenu
          resInfo.userPageMenu = res.data.childs

          // resInfo.menus = getAllList(res.data.childs)
          // resInfo.points = ['addRegion']
        })
        .catch((err: any) => {
          console.log(err)
        })

      // const res = {
      //   id: 1,
      //   menus: ['/roleManagement', '/menuManagement', '/userManagement'],
      //   points: ['addRegion'],
      // }
      // console.log(resInfo)
      this.commit('setUserInfo', resInfo)
      return resInfo
    },

    // 根据权限筛选路由
    filterRoutes(context: any, { routes, permissions }) {
      // 判断权限
      const hasPermission = function (route: any, permissions: any) {
        // console.log(route)
        // console.log(permissions)
        //需要权限才能访问
        // if (route.meta && route.meta.permission) {
        if (route.meta.requiresAuth) {
          //判断是否有访问该路由的权限 , 结果是 true/false
          return permissions.includes(route.path)
          //不需要权限就可以访问
        } else {
          return true
        }
      }

      var accessRoutes = []
      //过滤routes这个路由表
      accessRoutes = routes.filter((route: any) => {
        //判断: 如果route表示的路由规则在用户的权限列表中,则用户有权限访问该路由,该路由就需要过滤出来.
        if (hasPermission(route, permissions)) {
          //如果route 这个路由有子路由表, 则继续过滤子路由表
          if (route.children && route.children.length) {
            //继续过滤子路由表( 调用 filterRoutes 自身 过滤子路由表 )
            // route.children = context.dispatch('filterRoutes', {
            //   routes: route.children,
            //   permissions: permissions,
            // })
            context
              .dispatch('filterRoutes', {
                routes: route.children,
                permissions: permissions,
              })
              .then((res: any) => {
                route.children = res
              })
          }
          return true
        } else {
          return false
        }
      })

      // 最后添加 不匹配路由进入 404
      // accessRoutes.push({
      //   path: '/:catchAll(.*)',
      //   // redirect: '/404',
      //   component: () =>
      //     import('@/views/authorityManagement/userManagement.vue'),
      //   meta: {
      //     requiresAuth: false,
      //   },
      // })
      context.commit('setRoutes', accessRoutes)
      //返回最终过滤出来的 用户有权限访问的路由.
      return accessRoutes
    },
  },
  modules: {},
})
