import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

/* 公开路由表 */
export var publicRoutes: Array<RouteRecordRaw> = [
  // 登陆页面
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
  },
  // 主体架子
  {
    path: '/',
    name: 'publicShell',
    meta: {
      title: '首页',
      requiresAuth: false,
    },
    // 重定向
    redirect() {
      return '/calculationResult'
    },
    component: () => import('@/components/publicShell.vue'),
    children: [
      // ----------------------------------- 油耗管理 -----------------------------------

      // 油耗管理 - 注脂量计算
      {
        path: '/calculationResult',
        name: 'CalculationResult',
        component: () =>
          import('@/views/greaseCalculation/calculationResult.vue'),
        meta: {
          title: '油耗管理 - 注脂量计算',
          requiresAuth: false,
        },
      },

      // 404页面
      {
        path: '/:catchAll(.*)',
        // redirect: '/404',
        component: () => import('@/views/404.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
]

// 原始路由表
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'publicShell',
    meta: {
      title: '首页',
      requiresAuth: false,
    },
    // 重定向
    redirect() {
      return '/calculationResult'
    },
    component: () => import('@/components/publicShell.vue'),
    children: [
      // ----------------------------------- 权限管理 -----------------------------------

      // 菜单管理
      {
        path: '/menuManagement',
        name: 'MenuManagement',
        component: () =>
          import('@/views/authorityManagement/menuManagement.vue'),
        meta: {
          title: '权限管理 - 菜单管理',
          requiresAuth: true,
        },
      },
      // 角色管理
      {
        path: '/roleManagement',
        name: 'RoleManagement',
        component: () =>
          import('@/views/authorityManagement/roleManagement.vue'),
        meta: {
          title: '权限管理 - 角色管理',
          requiresAuth: true,
        },
      },
      // 用户管理
      {
        path: '/userManagement',
        name: 'UserManagement',
        component: () =>
          import('@/views/authorityManagement/userManagement.vue'),
        meta: {
          title: '权限管理 - 用户管理',
          requiresAuth: true,
        },
      },

      // ----------------------------------- 油耗管理 -----------------------------------

      // 油耗管理 - 注脂量计算
      {
        path: '/calculationResult',
        name: 'CalculationResult',
        component: () =>
          import('@/views/greaseCalculation/calculationResult.vue'),
        meta: {
          title: '油耗管理 - 注脂量计算',
          requiresAuth: false,
        },
      },

      // 油耗管理 - 轴承类别管理
      {
        path: '/bearingTypeManagement',
        name: 'BearingTypeManagement',
        component: () =>
          import('@/views/greaseCalculation/bearingTypeManagement.vue'),
        meta: {
          title: '油耗管理 - 轴承类别管理',
          requiresAuth: true,
        },
      },

      // ----------------------------------- 系统管理 -----------------------------------

      // 系统管理 - 区域管理
      {
        path: '/regionManagement',
        name: 'RegionManagement',
        component: () =>
          import('@/views/systemManagement/regionManagement.vue'),
        meta: {
          title: '系统管理 - 区域管理',
          requiresAuth: true,
        },
      },
      // 系统管理 - 设备管理
      {
        path: '/deviceManagement',
        name: 'deviceManagement',
        component: () =>
          import('@/views/systemManagement/deviceManagement.vue'),
        meta: {
          title: '系统管理 - 设备管理',
          requiresAuth: true,
        },
      },
    ],
  },

  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    // component: resolve =>
    //   require(['@/views/login.vue'], resolve),
    meta: {
      title: '登录',
    },
  },
]

// 前进后退是定位到原来位置
const scrollBehavior = function scrollBehavior(
  to: any,
  from: any,
  savedPosition: any
) {
  if (savedPosition) {
    return savedPosition
  } else {
    if (from.meta.keepAlive) {
      from.meta.savedPosition = document.body.scrollTop
    }
    return { x: 0, y: to.meta.savedPosition || 0 }
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  // routes,
  routes: publicRoutes,
  scrollBehavior,
})

// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = '轴承用脂量计算软件'
  // next()
  if (to.meta.requiresAuth) {
    if (sessionStorage.getItem('manageUserId')) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }, // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由
      })
    }
  } else {
    next() // 没有启动守卫 - 随他去吧
  }
  // if (to.meta.title) {
  //   document.title = to.meta.title + '- 流体科技管理系统'
  // } else {
  //   document.title = '流体科技管理系统'
  // }
  // //获取localStorage里面的token
  // let username = localStorage.getItem('username')
  // let uid = localStorage.getItem(username + 'uid')
  // let token = localStorage.getItem(uid + 'token')
  // //判断要去的路由有没有requiresAuth
  // if (to.meta.requiresAuth) {
  //   if (token) {
  //     next()
  //   } else {
  //     localStorage.clear()
  //     // ElementUI.Message.error('登录已失效，请重新登录')
  //     next({
  //       path: '/',
  //       query: { redirect: to.fullPath }, // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由
  //     })
  //   }
  // } else {
  //   next() // 没有启动守卫 - 随他去吧
  // }
})

export default router
