import router from '@/router/index'
import store from '@/store/index'
// 私有路由表
import { privateRoutes } from '@/router/routerList'

// 路由白名单
const whiteList = ['/login', '/calculationResult']

let isAddRouter = false
/**
 * 路由前置守卫
 * to 要去哪里
 * from 当前导航正要离开的路由
 * next 往哪去
 */
router.beforeEach(async (to, from, next) => {
  // if (store.getters.token) {
  if (sessionStorage.getItem('manageUserId')) {
    if (to.path === '/login') {
      // next('/')
      next()
    } else {
      // 判断用户信息是否获取
      // 若不存在用户信息，则需要获取用户信息
      // if (!store.getters.hasUserInfo) {
      if (!sessionStorage.getItem('userInfoManagement')) {
        const permission = await store.dispatch('getUserInfo')
        // 处理用户权限，筛选出需要添加的权限
        const filterRoutes = await store.dispatch('filterRoutes', {
          routes: privateRoutes,
          permissions: permission.menus,
        })
        // console.log(filterRoutes)
        if (!isAddRouter) {
          // 利用 addRoute 循环添加
          filterRoutes.forEach((item: any) => {
            router.addRoute(item)
          })

          // 添加完动态路由之后，需要在进行一次主动跳转
          isAddRouter = true
          // 这一行就是解决问题的最终办法, 重新进当前路由
          return next({ ...to, replace: true })
        } else {
          return next()
        }
      }
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
