import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// element-plus 全局引用
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

// 使用animate.css动画
import 'animate.css'

// 使用权限管理动态路由
import '@/router/permission'

import setDirectives from '@/directives/index'

//去除谷歌浏览器的scroll、wheel等事件警告
declare global {
  interface EventTarget {
    addEventListener(
      type: string,
      listener: EventListenerOrEventListenerObject | null,
      options?: boolean | AddEventListenerOptions
    ): void
  }
}
if (typeof EventTarget !== 'undefined') {
  const originalAddEventListener = EventTarget.prototype.addEventListener

  // 使用类型断言来覆盖 TypeScript 的类型检查
  ;(EventTarget.prototype as any).addEventListener = function (
    type: string,
    fn: EventListenerOrEventListenerObject | null,
    capture?: boolean | AddEventListenerOptions
  ) {
    if (typeof capture !== 'boolean') {
      // 如果 capture 不是布尔值，则将其转换为对象并设置 passive 为 false
      capture = capture || {}
      ;(capture as AddEventListenerOptions).passive = false
    }

    // 调用原始的 addEventListener 方法
    originalAddEventListener.call(this, type, fn, capture)
  }
}

// 使用UEditor百度富文本
import VueUeditorWrap from 'vue-ueditor-wrap'

createApp(App)
  .use(store)
  .use(router)
  .use(setDirectives)
  .use(VueUeditorWrap)
  .use(ElementPlus, { locale: zhCn })
  .mount('#app')
