import type { App } from 'vue'
import debounce from './modules/debounce'
import throttle from './modules/throttle'
import permission from './modules/permission'

const directivesList: any = {
  debounce,
  throttle,
  permission,
}

const setDirectives = {
  install(app: App<Element>) {
    Object.keys(directivesList).forEach((key) => {
      app.directive(key, directivesList[key])
    })
  },
}

export default setDirectives
